import React from 'react';
import styled from 'styled-components';
import { withPage } from '../Page';
import ImgTextRow from '../Components/ImgTextRow';

const ItemData = [
  {
    title: '緣起', subtitle: `TEL25二十五電訊成立於2017年，起先致力於語音網路市場(Voice over Internet Protocol)服務，現以出國旅遊會使用到的數據漫遊網卡(Data Roaming sim card)為主打商品，全心投入於各國數據漫遊資源間的整合，只為給消費者體驗更便利的網路服務，藉由持續的創新、改變與突破，打造全新多元的數位化時代，未來更將推出貼近消費者日常旅遊的電信服務(TEL U APP)。`,
    subtitlePosition: "bottom", buttonDisplay: false, buttonText: "",
    image: "photo10.png", reverse: true,
  },
  {
    title: '關於',
    subtitle: `TEL25二十五電訊是由一群有理想、抱負的年輕人所組織而成的電信公司。我們希望藉由地球村的概念，提供消費者電信網路無國界的服務。我們的團隊充滿著熱情與活力，重視消費者的體驗反饋，並積極吸取各國電信的經驗，所有夥伴秉持著不斷的創新、改變，進而優化提供給消費者更好的使用者體驗。您可以在TEL25找到任何您想要的漫遊方案，享受到最優質的網路服務。\n\n「TEL25 為您存在」。`,
    subtitlePosition: "bottom", buttonDisplay: false, buttonText: "",
    image: "photo11.png", reverse: false,
  },
  {
    title: '優勢',
    subtitle: `完整的數據資料\n多樣化的產品方案\n親切即時的線上客服\n完善的售後服務\n\nTEL25將所有消費者當成家人般對待\n因為只想給您們最好的`,
    subtitlePosition: "bottom", buttonDisplay: false, buttonText: "",
    image: "photo12.png", reverse: true,
  },

];

class AboutUsPage extends React.Component {
  render() {
    return (
      <Wrapper>
        <Container>
          {
            ItemData.map((d, i) => (
              <ImgTextRow
                key={"about-us" + i}
                data={d}
              />
            ))
          }
        </Container>
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  padding: 150px 25px 120px;
  width: 100vw;
`;

const Container = styled.div`
  margin: 0 auto;
  max-width: 1120px;
`;



export default withPage(AboutUsPage);
